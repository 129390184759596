<template>
    
    <div>

        <CheckboxRow 
            :label="labels.newsLetter"
            v-bind:value.sync="form.newsLetter" 
            :rowClass="{'col-12':true, 'form-group':true}" 
        />

        <CheckboxRow 
            v-if="!isRegister"
            :label="labels.annuaire"
            v-bind:value.sync="form.annuaire" 
            :rowClass="{'col-12':true, 'form-group':true}" 
        />

        <Select
            :label="labels.status"
            :placeholder="labels.status_placeholder"
            :selectOptions="{
                options: listStatus,
                getOptionLabel : option => option.name,
                reduce : option => option.value
            }"
            v-bind:value.sync="form.status" 
            :required="true"
        />

        <TextField 
            v-if="showMatricule"
            v-bind:value.sync="form.matricule" 
            :label="labels.matricule" 
            :placeholder="labels.matricule_placeholder" 
            :rowClass="{'col-lg-12':true, 'form-group':true}" 
            :inputClass="{'form-control':true, 'sm-form-control':true}" 
            fieldType="number"
            :required="true"
        />

    </div>

</template>

<style>

.register-form {
    min-height:50vh;
    max-width:800px;
    margin-left:auto;
    margin-right:auto;
}

</style>

<script lang="ts">
import { defineComponent, PropType, watch, computed, onMounted, onBeforeUnmount, ref } from '@fwk-node-modules/vue'
import TextField from '@fwk-client/components/panels/input/TextField.vue';
import CheckboxRow from '@fwk-client/components/panels/input/CheckboxRow.vue';
import Select from '@fwk-client/components/panels/input/Select.vue';
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';

/** @cmsOptions */
export interface ProfileMetaOptions {
    isRegister:boolean;
}

/** @cmsLabels */
export interface ProfileMetaLabels {
    
}

/** @cmsSlots */
export interface ProfileMetaSlots {
    
}

export default defineComponent({
    props: {
        options: Object as PropType<ProfileMetaOptions>,
        labels: {
          type: Object as PropType<ProfileMetaLabels>,
          default: () => { return {} }
        },
        value: Object,
        components: Object as PropType<ProfileMetaSlots>
    },
    components: {
        TextField,
        CheckboxRow,
        Select
    },
    setup(props, context) {
        const app = getApp();
        const $router = useRouter();
        const $store = useStore();

        const form = ref({
            matricule:props.value && props.value.matricule != undefined ? props.value.matricule : "",
            newsLetter:props.value && props.value.newsLetter != undefined ? props.value.newsLetter : true,
            annuaire:props.value && props.value.annuaire != undefined ? props.value.annuaire : true,
            status:props.value && props.value.status != undefined ? props.value.status : "",
        })

        const listStatus:any[] = [
            {
                name: "Sapeur pompier professionnel (SPP)",
                value: "SPP"
            },
            {
                name: "Sapeur pompier volontaire (SPV)",
                value: "SPV"
            },
            {
                name: "Personnel administratif (PATS)",
                value: "PATS"
            },
            {
                name: "Retraité",
                value: "RETIRED"
            }
        ];

        const labels = {
            matricule: "Votre matricule",
            matricule_placeholder: "Votre matricule",
            newsLetter: "Adhésion à la lettre d'information",
            annuaire: "Apparition dans l'annuaire des membres",
            status: "Votre statut",
            status_placeholder: "Votre statut",
        }
        

        const computedOptions:ProfileMetaOptions = {
            isRegister:false,
            ...props.options
        };

         const showMatricule = computed(() => {
            return form.value.status != "RETIRED";
        })

        watch(() => props.value, (newValue:any) => {
            form.value = newValue;
        },
        {
            deep: true
        });

        watch(form, (newValue:any) => {
            if(!showMatricule.value) {
                delete newValue.matricule;
            }
            context.emit('update:value', newValue);
        },
        {
            deep: true
        })

        return {
            form,
            labels,
            listStatus,
            showMatricule,
            isRegister:computedOptions.isRegister
        }

    }
})
</script>